import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.css';
import WorkOrder from './work_order.js';
import FestiveCalendar from './festive_calendar.js';
import ToggleAbsence from './toggle_absence.js';


$(document).ready(function(){
  $('.custom-check-box-wrapper label').prepend('<span></span>');
  $('.custom-radio-button-wrapper label').prepend('<span></span>');
  $('.custom-check-boxes input[type="checkbox"] + label').prepend('<span></span>');
  $('.custom-check-boxes .form-group > .form-check').wrap('<div class="col-6 mb-3"></div>');
  $('.custom-check-boxes .form-group').addClass('row');


  //select2 selectors
  $('.selectized').select2();
  $('.selectized-users, .selectized-clients, .wo-project-select').select2({
    minimumInputLength: 2,
    allowClear: true,
    placeholder: "Seleccionar opció"
  });
  function update_project_client(){
    if ($(".wo-project-select").length){
      ajax_get_client($(".wo-project-select"));
    }
    $(".wo-project-select").on("select2:select", function (e) {
      ajax_get_client(this);
    });
    function ajax_get_client(el) {
      let id = $(el).val();
      if( id != ""){
        $.ajax({
          type: 'GET',
          url: "/projects/" + id + "/get_project",
          dataType: "script"
        });
      }
    }
  };
  //work_order.js
  WorkOrder();
  FestiveCalendar();
  ToggleAbsence();
  update_project_client();
  //Project JS
  $("#project_category_0").on("click", function(){
    $("#project_expected_import").prop("disabled", false);
  });
  $("#project_category_1").on("click", function(){
    $("#project_expected_import").prop("disabled", true);
  });

  $("#project_client_id").on("change", function() {
    $('#project_work_center_id').empty();
    let client = $(this).val();
    if( client != ""){
      $.ajax({
        type: 'GET',
        url: "/projects/get_work_centers",
        dataType: "script",
        data: { client: client }
      });
    }
  });
});
