export default function mark_calendar_date() {
  $(".toggleable .day-content").on("click", function(){
    ajax_toggle_date(this);
  });

  function ajax_toggle_date(el) {
    let day = $(el)
    let id = day.parent().parent().parent().data("id");
    $.ajax({
      type: 'POST',
      url: "/calendars/" + +id + "/toggle-date",
      data: {date: day.data("date")},
      dataType: "script",
      success: function(data) {
        day.parent().toggleClass('festive-background');
        day.parent().toggleClass('marked');
      }
    });
  }
}
