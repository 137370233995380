export default function addWorkOrder() {
  $('.add-work-order').on("click", function(){ add_new_input_row('work-order', 'work_order[work_order_items_attributes]'); });
  $('.add-work-center').on("click", function(){ add_new_input_row('work-center', 'client[work_centers_attributes]'); });
  $('.time_work_order').on("change", function(){ set_total_time(); });
  $('.remove-work-center').on("click", function(){
    var imgElement = $(this);
    var parentDiv = imgElement.closest('[id^="work-center-item-form_"]');
    var parentDivId = parentDiv.attr('id');
    var elementId = parentDivId.split('_').pop();
    parentDiv.remove();
    var hiddenElement = $('#client_work_centers_attributes_' + elementId + '_id');
    hiddenElement.after(hiddenElement.clone());
    var newHiddenElement = $('#client_work_centers_attributes_' + elementId + '_id').last();
    newHiddenElement.attr('id', 'client_work_centers_attributes_' + elementId + '_deleted');
    newHiddenElement.attr('name', 'client[work_centers_attributes][' + elementId + '][deleted]');
    newHiddenElement.attr('value', '1');
  });
  set_total_time();
}

function set_total_time() {
  if($('.total-hores-calc._js').length) {
    var mins = $.map( $('.work-order-item-form'), function( node ) {
      return get_mins( node );
    }).reduce((a, b) => a + b, 0)
    $('.total-hores-calc').text(Math.floor(mins/60) + ":" + ('0' + mins%60).slice(-2) + " h")
  }
}

function get_mins( parent_node ){
  var klasses = ['entrance_hour', 'entrance_min', 'departure_hour', 'departure_min'];
  var filled = true;

  $.map( klasses, function( val ) {
    var input_value = $(parent_node).find('.' + val + '_js').val();
    if ( input_value == "" ) { filled = false }
  });
  if ( filled == true ) {
    var e_hours = $(parent_node).find('.entrance_hour_js').val();
    var d_hours = $(parent_node).find('.departure_hour_js').val();
    var e_mins = $(parent_node).find('.entrance_min_js').val();
    var d_mins = $(parent_node).find('.departure_min_js').val();
    var time = ( d_hours - e_hours )*60 + ( d_mins - e_mins )
    return time >= 0 ? time : 0
  } else {
    return 0;
  }
}

function randomize_input(klass, form, id, html_name) {
  var form_item = form.find('.' + klass + '_js').last();
  form_item.attr('name', html_name + '[' + id + '][' + klass + ']');
  (klass == 'tipus_hora') ? form_item.val($('.' + klass + '_js' + " option:first").val()) : form_item.val('');
  form_item.on("change", function(){
    set_total_time();
  });
}

function add_new_input_row(target_klass, html_name){
  var id = new Date().getTime();
  var form = $('.' + target_klass + '-item-form').last();
  form.after(form.clone());
  var new_form = $('.' + target_klass + '-item-form').last();
  var klasses = ['entrance_hour', 'entrance_min', 'departure_hour', 'departure_min', 'description', 'name', 'distance', 'tipus_hora']
  $.map( klasses, function( val ) { randomize_input( val, new_form, id, html_name) });
}
